$animate: all 0.2s ease-in-out;

.menulinks {
  font-family: "Oswald";
  font-weight: bold;
  color: #f0f0f0;
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0px;
    height: 2px;
    margin: 5px 0 0;
    transition: $animate;
    transition-duration: 0.15s;
    opacity: 0;
    background-color: darken(#f0f0f0, 5%);
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
  &:hover {
    cursor: pointer;
    &:before,
    &:after {
      width: 50%;
      opacity: 1;
    }
  }
}
