.App {
  text-align: center;
  background-color: #282c34;
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  /* pointer-events: none; */
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url("./imgs/background.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 110vw 110vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  transition:transform 0.2s;
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 100vh;
}

.App-header.is-nav-open {
  transform: perspective(5000px) rotateX(25deg);
  transform-origin: center bottom;
}

.menu-icon, h3, h4 {
  cursor: pointer;
}

header {
  transition:transform 1s;
  margin-top: 20px;
}

/*setting fonts*/
h1, p {
  font-family: 'Montserrat', 'Roboto';
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.row {
  width: 100vw;
}

#click {
  font-size: 0.6em;
  cursor: pointer;
  animation: flashing 2s ease-in-out infinite;
}

#menu-container {
  top: 5vh;
  width: 50vw;
  height: 5em;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-container input {
  display: flex;
  width: 60px;
  height: 60px;
  position: absolute;
  cursor: pointer;
  opacity: 0; /* hide input */
  z-index: 1; /* top of the menu */
}

#menu-container span{
  width: 32px;
  height: 4px;
  margin-bottom: 4px;
  background: #f0f0f0;
  border-radius: 4px;
  /* animation: flashing 2s ease-in-out infinite; */
  -webkit-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  -moz-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  -ms-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  -o-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  transition: all .5s cubic-bezier(.08,.81,.87,.71);
}

#span1 {
  transform-origin: center left;
  animation: flashing 2s ease-in-out infinite;
}

#span2 {
  transform-origin: center center;
}

#span3 {
  transform-origin: center left;
  animation: flashing 2s ease-in-out infinite;
}

#menu-container input:checked ~ #span1 {
  background-color: #f0f0f0;
  transform: rotate(45deg) translate(-4px, -4px);
  width: 38px;
  animation: flashing 2s ease-in-out infinite;
}
#menu-container input:checked ~ #span2 {
  background-color: #f0f0f0;
  transform: rotate(495deg) translate(0px);  
  opacity: 0; 
}
#menu-container input:checked ~ #span3 {
  background-color: #f0f0f0;
  transform: rotate(-45deg) translate(-4px, 4px);
  width: 38px;
  animation: flashing 2s ease-in-out infinite;
}

#menu-container input:checked ~ #click {
  animation: none;
  opacity: 0;
}

@keyframes flashing {
  0% { 
      opacity: 0;
  }
  70% {
      opacity: 1;
  }
  100% { 
      opacity: 0;
  }
}
