.exit-button {
    float: right;
    border: none;
    font-size: 2em;
    color: #696969;
    &:hover {
        color: black;
        animation: exit-button-spin infinite 2s linear;
    }
}

@keyframes exit-button-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }