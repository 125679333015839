.about-content {
    margin: auto;
    width: 50vw;
    height: 98vh;
    position: absolute;
    top: 5px;
    color: #696969;
    background-color: #fefefa;
    padding: 1rem;
    overflow: scroll;
    font-size: 1rem;
    border-radius: 0.5rem;
    .title {
        height: 4em;
    }
    & p {
        text-align: left;
        font-size: 1em;
        font-family: sans-serif;
        font-weight: normal;
    }
    & h1 {
        float: left;
    }
}


